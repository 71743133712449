var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contactus-1 bg-default",style:("background-color: rgb(23, 43, 77)")},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-lg-5 col-md-7 ml-auto mr-auto mt-2"},[_c('card',{attrs:{"headerClasses":"text-center"}},[_c('template',{staticClass:"text-center",slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Contact Us")])]),_c('template',{slot:"body"},[(_vm.message_sent)?_c('div',[_c('base-alert',{attrs:{"dismissible":"","icon":"fa fa-check","type":"success"}},[_c('div',{attrs:{"slot":"text"},slot:"text"},[_vm._v(" Your message has been sent. ")])])],1):_vm._e(),_c('validation-observer',{ref:"observer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("First name")]),_c('base-input',{attrs:{"error":errors[0],"placeholder":"First name"},model:{value:(_vm.message.first_name),callback:function ($$v) {_vm.$set(_vm.message, "first_name", $$v)},expression:"message.first_name"}})]}}])})],1)]),_c('div',{staticClass:"col-md-6 pl-2"},[_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Last name")]),_c('base-input',{attrs:{"error":errors[0],"placeholder":"Last name"},model:{value:(_vm.message.last_name),callback:function ($$v) {_vm.$set(_vm.message, "last_name", $$v)},expression:"message.last_name"}})]}}])})],1)])]),_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"Email address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Email address")]),_c('base-input',{attrs:{"error":errors[0],"placeholder":"Email address"},model:{value:(_vm.message.email),callback:function ($$v) {_vm.$set(_vm.message, "email", $$v)},expression:"message.email"}})]}}])})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"name":"Message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Your message")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message.message),expression:"message.message"}],staticClass:"form-control",attrs:{"name":"message","id":"message","rows":"6"},domProps:{"value":(_vm.message.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.message, "message", $event.target.value)}}}),_c('small',[(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}])})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 ml-auto"},[_c('base-button',{staticClass:"pull-right",attrs:{"nativeType":"submit","type":"login"},on:{"click":function($event){return _vm.sendMessage()}}},[_vm._v("Send Message")])],1)])])],1)],2)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-5 col-md-5 d-flex justify-content-center flex-column"},[_c('h2',{staticClass:"title text-white"},[_vm._v("Get in Touch")]),_c('h4',{staticClass:"description text-white"},[_vm._v(" You need more information? Check what other persons are saying about our product. They are very happy with their purchase. ")]),_c('div',{staticClass:"info info-horizontal"},[_c('div',{staticClass:"icon icon-shape shadow rounded-circle text-white bg-myprimary"},[_c('i',{staticClass:"fa fa-map-marker"})]),_c('div',{staticClass:"description"},[_c('h4',{staticClass:"info-title text-white"},[_vm._v("Find us at the office")]),_c('p',{staticClass:"description ml-3 text-white"},[_vm._v(" R. Agostinho da Silva Rocha 945,"),_c('br'),_vm._v(" 4475-451 Nogueira Maia Portugal,"),_c('br')])])]),_c('div',{staticClass:"info info-horizontal"},[_c('div',{staticClass:"icon icon-shape shadow rounded-circle text-white bg-myprimary"},[_c('i',{staticClass:"fa fa-phone"})]),_c('div',{staticClass:"description"},[_c('h4',{staticClass:"info-title text-white"},[_vm._v("Give us a ring")]),_c('p',{staticClass:"description ml-3 text-white"},[_c('a',{staticClass:"text-white",attrs:{"href":"tel:+351 22 961 77 00","target":"_blank"}},[_vm._v("+351 22 961 77 00")]),_c('br'),_c('a',{staticClass:"text-white",attrs:{"href":"tel:+351 22 493 33 24","target":"_blank"}},[_vm._v("+351 22 493 33 24")])])])])])}]

export { render, staticRenderFns }